<template>
  <div class="jobs">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate
      style="background: #fc00ff"
      :title="title.titlePage"
      :subtitle="title.subtitlePage"
      :img="title.img"
    />
    <InformationsTemplate :card="caritas" />
    <InformationsTemplate :card="catedra" />
    <InformationsTemplate :card="pantheon" />
    <InformationsTemplate :card="seeduc" />
    <InformationsTemplate :card="sme" />
    <InformationsTemplate :card="csvm" />
    <p><router-link :to="{ name: 'refugees-menu-PT' }">{{$t("education.linkPage")}}</router-link></p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy,
  },
  data() {
    return {
      title: {
        titlePage: this.$t("education.titlePage"),
        subtitlePage: this.$t("education.subtitlePage"),
        img: "education.png",
      },
      catedra: {
        title: this.$t("education.catedraTitle"),
        link: "https://www.acnur.org/portugues/catedra-sergio-vieira-de-mello",
        linkName: this.$t("education.linkName"),
      },
      caritas: {
        title: this.$t("education.caritasTitle"),
        link: "https://www.caritas-rj.org.br/refugiados-nas-escolas",
        linkName: this.$t("education.linkName"),
      },
      pantheon: {
        title: this.$t("education.pantheonTitle"),
        link: "https://pantheon.ufrj.br/handle/11422/2255",
        linkName: this.$t("education.linkName"),
      },
      seeduc: {
        title: this.$t("education.seeducTitle"),
        link: "https://www.seeduc.rj.gov.br/",
        linkName: this.$t("education.linkName"),
      },
      sme: {
        title: this.$t("education.smeTitle"),
        link: "http://www.rio.rj.gov.br/web/sme",
        link2: "https://www.google.com.br/maps/search/secretarias+de+educa%C3%A7%C3%A3o+dos+municipios/@-22.8511965,-43.3460287,12z/data=!3m1!4b1",
        linkName: this.$t("education.smeLinkName"),
        linkName2: this.$t("education.linkName2")
      },
      csvm: {
        title: this.$t("education.csvmTitle"),
        link: "https://www.acnur.org/portugues/catedra-sergio-vieira-de-mello/universidades-conveniadas/",
        linkName: this.$t("education.linkName"),
      },
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0 0 50px 120px;
}
</style>